import { clsx } from "clsx";
import type { MouseEventHandler, ReactNode } from "react";
type RegularButtonProps = {
  readonly className?: string;
  readonly disabled?: boolean; // eslint-disable-line react/boolean-prop-naming -- The name should match the official one in HTML.
  readonly onClick?: MouseEventHandler<HTMLButtonElement>;
  readonly children: ReactNode;
  readonly variant?: "primary" | "accent" | "secondary";
};
export function RegularButton({
  className,
  disabled,
  onClick,
  children,
  variant = "primary"
}: RegularButtonProps) {
  return <button className={clsx(className, "base-button base-hover-button border text-white", variant === "primary" && "border-primary bg-primary hover:border-primary-hover hover:bg-primary-hover", variant === "accent" && "border-accent bg-accent hover:border-accent-hover hover:bg-accent-hover", variant === "secondary" && "border-secondary bg-secondary hover:border-secondary-hover hover:bg-secondary-hover")} data-testid="regular-button" disabled={disabled} type="button" onClick={onClick} data-sentry-component="RegularButton" data-sentry-source-file="RegularButton.component.tsx">
      {children}
    </button>;
}